import React from 'react';
import {  graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { AnchorLink } from "gatsby-plugin-anchor-links";

import Mail from "../../static/svg/icon-mail.inline.svg";
import Call from "../../static/svg/icon-call.inline.svg";
import ExternalLink from "../../static/svg/icon-external-link.inline.svg";



import SEO from "../../components/seo";
import LayoutSub from "../../components/layoutSub";
import NishiogiMap from "../../components/nishiogiMap";




function Nishiogi({data, pageContext, location}) {

  const { breadcrumb: { crumbs } } = pageContext;
  
  const {pathname} = location;
  const pagetitle = "Nishiogikubo: Nishiogi Osteopathy Center";
  const description = "Nishiogikubo: Are you looking for relief from pain or discomfort?  We're here to help you at Penguindou Nishiogikubo branch.";

    return (
      <div>

        <LayoutSub crumbs={crumbs} pathname={pathname} lang='en' >
          <SEO
            pageurl={pathname}
            pagetitle={pagetitle}
            description={description}
            lang="en"
          />

          <div>
            <h1 className="heading-b">Informamtion</h1>

            <p>We provide our service at Nishiogi Osteopathy Center.</p>

            <p className="text-lg mb-0">5 minutes from Nishiogikubo sta.&nbsp;&#40;JB03&#41; on JR Chuo/Sobu line</p>

            <p className="mt-0"><span className="font-semibold">Address:&nbsp;</span>{data.site.siteMetadata.addresse},&nbsp; {data.site.siteMetadata.postalcode} </p>
            <div className="sm:pointer-events-none">
            <a href={`tel:${data.site.siteMetadata.tel}`.replace(/\s+/g, "")}>
              <p className="text-xl underline font-semibold sm:no-underline mb-0 ">
                <Call className="inline h-6 w-6 pb-0.5 ml-1" />
                {data.site.siteMetadata.tel}
              </p>
            </a>
            </div>
            <p className="mt-0 text-sm sm:hidden">(Tap to call)</p>
            <p className="mt-0 mb-1"><span className="font-semibold">telephone reception hours：</span>Mon to Sat 10:00~18:00</p>
            <p>If you come through to the answering machine, please leave a message and we will call you back as soon as possible.</p>


            <AnchorLink  to="/en/contact/#form" >
              <button type='button' className="border border-emerald-600 bg-emerald-500 text-white hover-hover:hover:bg-emerald-600  text-center my-4 py-2 px-4 rounded font-semibold shadow ">

                <Mail className="h-6 w-6 inline-block mr-2 align-bottom" />
                Contact Us

              </button>
            </AnchorLink >

            <p className="my-1"><span className="font-semibold">Open:&nbsp;</span>Monday, Tuesday, Thursday and the third Saturday</p>
            <p className="pl-4">
        We&apos;re open from 10:00 to 18:00.<br />
        Last entry is at 16:30.  Please let us know if you are not able to come before 16:30, there are days you can visit us after 16:30.<br />
      </p>

            <p className="my-1"><span className="font-semibold">Saturday business day of December:</span></p>
            {/*
            <p className="pl-4">Saturday business day in September is 16th.</p>

            We&apos;re also open on <span className="marker">9th October &#40;national holiday&#41;, Monday
    */}
            
            {/* <p className="pl-4">Saturday business day in November is 23rd.</p> */}
            <p className="pl-4">We usually open on the third Saturday, but this month we will be open on the fourth Saturday, 28th December.</p>

            <p className="mt-1.5 mb-1 font-semibold">Price:</p>
            <p className="pl-4 mt-0 mb-1">Initial Consultation – &yen;8,800<br />Follow-Up Appointments – &yen;6,600</p>

            <p className="mb-0">
        <span className="font-semibold">Session length：</span></p>
        <p className="mt-0 pl-4 mb-3">About 90 minitues for the first session<br />About 60 minutes for the follow-up session
      </p>


            <h2 id="access" className="heading-l">Nishiogikubo Access</h2>

            <p className="mt-6">
              <a href="https://www.google.com/maps/place/%E8%BA%AB%E4%BD%93%E3%81%AE%E8%AA%BF%E6%95%B4+%E3%81%BA%E3%82%93%E3%81%8E%E3%82%93%E5%A0%82%E3%82%AA%E3%82%B9%E3%83%86%E3%82%AA%E3%83%91%E3%82%B7%E3%83%BC/@35.7008897,139.6001098,16z/data=!4m5!3m4!1s0x6018efb916a1ec21:0x130ac75b617c9130!8m2!3d35.7008897!4d139.6001098?hl=en" target="_blank" rel="noreferrer" >GoogleMap
                <ExternalLink className="inline  w-4 h-4 align-text-bottom pb-0.5" />
              </a></p>
            <p className="mt-2">
            5 minutes from Nishiogikubo sta.&nbsp;&#40;JB03&#41; on JR Chuo/Sobu line
            </p>
            <div className="mb-6 responsive-map">
          <StaticImage
              src="../../images/NishiogiMapEn.jpg"
              alt="Matp to Penguindou from Nishiogikubo station south exit"
              placeholder="tracedSVG"
              layout="constrained"
              width={534}
           />
            </div>

            <NishiogiMap lang='en' />

          </div>


        </LayoutSub>
      </div>

      
    )
}

export default Nishiogi;

export const query = graphql`
query {
  site {
  siteMetadata {
  siteUrl
  addresse
  tel
  postalcode
}
}
}
`